import React from "react";
import Span from "./Span";


const Data = () => {
    return (
        <div id = "data">
            <Span  text =" © 2021 stadtradet.se" />
        </div>
    )
}

export default Data