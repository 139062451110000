import React from "react";
import Span from "./Span";

const PolicyConditions = () => {
    return (
        <div className = "blue_color">
            <Span text = "created by Tatev"/>          
        </div>
    )
}

export default PolicyConditions