
import React from "react";
import MenuUl from "./menuUl";



export default function MenuMax() {
    return (
        <div className="menu_max_nav" >
            <MenuUl />
          
        </div>

    )
}