

const LogoHeader = () => {
    return (
        <div id="logo">
            <div className="logoPic_info_wrapper">
                <div className="logoPic"></div>
            </div>

        </div>
    )
}

export default LogoHeader;